const Error = () => {
    return(
        <>

       <div className="container">
         <div className="row">
           <div className="col-12">
             <img src="https://error404.fun/img/full-preview/1x/15.png" alt="404 Error" className="erroimg" />
           <h4 className="error_page_text">404 Sorry Page Not Found</h4>

           </div>
         </div>
       </div>

     
        </>
    )
}
export default Error;